<template>
  <div>
    <b-card no-body class="card-statistics">
      <b-card-header class="p-2">
        <b-card-title>Totalizador de clientes</b-card-title>
      </b-card-header>
      <b-card-body class="px-1">
        <b-row class="grid-status">
          <b-col cols="auto" class="mt-1">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <div class="circle total_customers">
                  <img
                    class="img_icon"
                    src="~@/assets/images/svg/icn_customers.svg"
                  />
                </div>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ totalizer[status.TOTAL_AMOUNT] || 0 }}
                </h4>
                <b-card-text class="font-small-3 mb-0"> Clientes </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col cols="auto" class="mt-1">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-button
                  class="btn-icon"
                  variant="flat"
                  style="padding: 0"
                  @click="filterStatus(status.CUSTOMER_STATUS_SUPPORT)"
                >
                  <div class="circle total_support">
                    <img
                      class="img_icon"
                      src="~@/assets/images/svg/icn_support.svg"
                    />
                  </div>
                </b-button>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ totalizer[status.CUSTOMER_STATUS_SUPPORT] || 0 }}
                </h4>
                <b-card-text class="font-small-3 mb-0"> Suporte </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col cols="auto" class="mt-1">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-button
                  class="btn-icon"
                  variant="flat"
                  style="padding: 0"
                  @click="filterStatus(status.CUSTOMER_STATUS_BASIC_SUB)"
                >
                  <div class="circle total_basic_sub">
                    <img
                      class="img_icon"
                      src="~@/assets/images/svg/icn_user_disable.svg"
                    />
                  </div>
                </b-button>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ totalizer[status.CUSTOMER_STATUS_BASIC_SUB] || 0 }}
                </h4>
                <b-card-text class="font-small-3 mb-0"> Básico </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col cols="auto" class="mt-1">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-button
                  class="btn-icon"
                  variant="flat"
                  style="padding: 0"
                  @click="filterStatus(status.CUSTOMER_STATUS_ESSENTIAL_SUB)"
                >
                  <div class="circle total_essential_sub">
                    <img
                      class="img_icon"
                      src="~@/assets/images/svg/icn_essential.svg"
                    />
                  </div>
                </b-button>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ totalizer[status.CUSTOMER_STATUS_ESSENTIAL_SUB] || 0 }}
                </h4>
                <b-card-text class="font-small-3 mb-0"> Essencial </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col cols="auto" class="mt-1">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-button
                  class="btn-icon"
                  variant="flat"
                  style="padding: 0"
                  @click="filterStatus(status.CUSTOMER_STATUS_ELITE_SUB)"
                >
                  <div class="circle total_elite_sub">
                    <img
                      class="img_icon"
                      src="~@/assets/images/svg/icn_premium.svg"
                    />
                  </div>
                </b-button>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ totalizer[status.CUSTOMER_STATUS_ELITE_SUB] || 0 }}
                </h4>
                <b-card-text class="font-small-3 mb-0"> Elite </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col cols="auto" class="mt-1">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-button
                  class="btn-icon"
                  variant="flat"
                  style="padding: 0"
                  @click="filterStatus(status.CUSTOMER_STATUS_EP)"
                >
                  <div class="circle total_ep">
                    <img
                      class="img_icon"
                      src="~@/assets/images/svg/icn_entrevistapaga_70.svg"
                    />
                  </div>
                </b-button>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ totalizer[status.CUSTOMER_STATUS_EP] || 0 }}
                </h4>
                <b-card-text class="font-small-3 mb-0"> EP </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col cols="auto" class="mt-1">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-button
                  class="btn-icon"
                  variant="flat"
                  style="padding: 0"
                  @click="
                    filterStatus(status.CUSTOMER_STATUS_CANCELED_SUBSCRIPTION)
                  "
                >
                  <div class="circle total_cancelled">
                    <img
                      class="img_icon"
                      src="~@/assets/images/svg/cash-clock.svg"
                    />
                  </div>
                </b-button>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{
                    totalizer[status.CUSTOMER_STATUS_CANCELED_SUBSCRIPTION] || 0
                  }}
                </h4>
                <b-card-text class="font-small-4 mb-0">
                  Assinatura cancelada
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>

          <b-col cols="auto" class="mt-1">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-button
                  class="btn-icon"
                  variant="flat"
                  style="padding: 0"
                  @click="filterStatus(status.CUSTOMER_STATUS_GESTAO)"
                >
                  <div class="circle gestao">
                    <img
                      class="img_icon"
                      src="~@/assets/images/svg/magnifying-glass-dollar.svg"
                    />
                  </div>
                </b-button>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ totalizer[status.CUSTOMER_STATUS_GESTAO] || 0 }}
                </h4>
                <b-card-text class="font-small-4 mb-0"> Gestão financeira </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BPopover,
  BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { mapActions } from "vuex";
import * as types from "../store/types";
import * as customerStatus from "@/constants/customers_status";
import { LEAD_TYPE_PCL } from "@/constants/lead";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    VueApexCharts,
    BPopover,
    BButton,
  },
  props: {
    totalizer: {
      type: Object,
      default: undefined,
    },
    filterStatus: Function,
  },
  computed: {
    status: function () {
      return customerStatus;
    },
  },
  methods: {
    ...mapActions({
      openNewLeadSidebar: types.OPEN_NEW_LEAD_SIDEBAR,
    }),
    newLead(lead) {
      this.openNewLeadSidebar({
        id: undefined,
        leadType: LEAD_TYPE_PCL,
      });
    },
  },
};
</script>

<style lang="scss">
.total_customers {
  background-color: #22505f;
}
.total_support {
  background-color: #223f61;
}
.total_basic_sub {
  background-color: #3d6075;
}
.total_essential_sub {
  background-color: #4a2859;
}
.total_elite_sub {
  background-color: #50500b;
}
.total_ep {
  background-color: #674c31;
}
.total_cancelled {
  background-color: #6b2231;
}
.gestao {
  background-color: #1d6952;
}
.circle {
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 100%;
}
.img_icon {
  width: 30px;
  height: 30px;
}
#buttons {
  border: 0px;
}

.grid-status {
  display: grid !important;
  grid-template-columns: repeat(7, 1fr);

  @media (max-width: 1455px) {
    & {
      grid-template-columns: repeat(3, 1fr) !important;
    }
  }

  @media (max-width: 986px) {
    & {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }

  @media (max-width: 768px) {
    & {
      grid-template-columns: 1fr 1fr !important;
    }
  }
}
</style>
