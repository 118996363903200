<template>
  <div>
    <b-sidebar id="sidebar-settings-table" sidebar-class="sidebar-lg" :visible="settingsTableSidebar.visible"
      bg-variant="white" shadow backdrop no-header right
      @change="(val) => mutateSettingsTableSidebar({ visible: val })">
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4>Personalizar tabela</h4>
          <div>
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
          </div>
        </div>
        <b-form v-if="settings" class="p-2" @submit.prevent="onSubmit" @reset.prevent="resetForm">
          Pesonalize os campos da sua tabela!
          <br>
          <br>
          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="settings.customer_name" switch inline>
              Nome
            </b-form-checkbox>
          </div>
          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="settings.customer_status_name" switch inline>
              Status
            </b-form-checkbox>
          </div>
          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="settings.last_interview_days" switch inline>
              Dias sem contato
            </b-form-checkbox>
          </div>
          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="settings.call_reminder" switch inline>
              Lembrar
            </b-form-checkbox>
          </div>
          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="settings.cash_flow" switch inline>
              Renda
            </b-form-checkbox>
          </div>
          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="settings.monthly_savings" switch inline>
              Poupa mensalmente
            </b-form-checkbox>
          </div>
          <div class="demo-inline-spacing">
              <b-form-checkbox v-model="settings.goals" switch inline>
                Objetivos
              </b-form-checkbox>
          </div>
          <div class="demo-inline-spacing">
             <b-form-checkbox v-model="settings.consultant_name" switch inline>
              Consultor Responsável
            </b-form-checkbox>
          </div>
          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="settings.franchise_name" switch inline>
              Franquia
            </b-form-checkbox>
          </div>
          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="settings.email" switch inline>
              Email
            </b-form-checkbox>
          </div>
          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="settings.anniversary_date" switch inline>
              Aniversário
            </b-form-checkbox>
          </div>
          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="settings.last_interview_date" switch inline>
              Última reunião
            </b-form-checkbox>
          </div>
          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="settings.became_customer_at" switch inline>
              Cliente a partir de
            </b-form-checkbox>
          </div>
          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="settings.is_vip" switch inline>
              Cliente VIP
            </b-form-checkbox>
          </div>
          <!-- </b-row> -->
          <div class="d-flex mt-2">
            <b-button :disabled="saving" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2"
              type="submit">
              {{ saving ? 'Salvando as informações...' : 'Salvar' }}
            </b-button>
          </div>
        </b-form>

      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormFile, BButton, BFormInput, BFormTextarea, BCol
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useVuelidate from '@vuelidate/core'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import MoneyInput from '@/modules/shared/components/MoneyInput'
import * as types from '../store/types'
import BCardCode from '@core/components/b-card-code'
import { BFormCheckbox, BCardText } from 'bootstrap-vue'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BCol,
    BCardCode,
    vSelect,
    MoneyInput,
    BFormCheckbox,
    BCardText,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      saving: false,
      settings: undefined
    }
  },
  computed: {
    ...mapGetters({
      settingsTableSidebar: types.SETTINGS_TABLE_SIDEBAR,
      settingsTableCustomer: types.SETTINGS_TABLE_CUSTOMERS,
      sequenceColumnEnabled: types.SEQUENCE_COLUMN_ENABLED
    })
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  watch: {
    settingsTableCustomer() {
      this.settings = { ...this.settingsTableCustomer }
    }
  },
  methods: {
    ...mapMutations({
      mutateSettingsTableSidebar: types.MUTATE_SETTINGS_TABLE_SIDEBAR,
    }),
    ...mapActions({
      saveSettingsTableSidebar: types.SAVE_SETTINGS_TABLE_SIDEBAR,
    }),
    onSubmit() {
      this.saving = true
      this.saveSettingsTableSidebar(this.settings)
        .then(response => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "A solicitação foi adicionado com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.mutateSettingsTableSidebar({ visible: false })
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao adicionar o recebimento. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.saving = false
        });
    },

  }
}
</script>
